import * as types from "../constants";

//TYPES
import { AnyAction } from 'redux';
import { Setting } from '../../types';

const initialState : Setting = {
    id: '',
    gdpr_bar_text: '',
    gdpr_content: '',
    step_2_instruction_image_overview_url: '',
    step_2_instruction_image_closeup_url: '',
    step_2_instruction_video_url: '',
    step_2_instruction_image_closeup_text: '',
    step_2_instruction_image_overview_text: '',
    step_2_otherpart_image_url: ''
};

export default function carReducer(state = initialState, action: AnyAction) { 
  switch (action.type) {
    case types.SET_GLOBAL_SETTING:
      return { ...state, ...action.payload };           
    default:
      return state;
  }
}