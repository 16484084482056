import { CircularProgress } from '@material-ui/core';
import * as React from "react";
import {
  Switch,
  Route
} from "react-router-dom";

// PAGES 

// import MainPage from './MainPage'
// import  ThanksPage from './ThanksPage'

const MainPage = React.lazy(() => import('./MainPage'));
const ThanksPage = React.lazy(() => import('./ThanksPage'));

const Main = () => {
  return (
    <>
      <React.Suspense fallback={<div className={'overlay'}><CircularProgress /></div>}>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route path="/thanks" component={ThanksPage} />
        </Switch>
      </React.Suspense>
    </>)

}

export default Main   