//MAIN
import random from "alphanumeric";

//HELPERS FUNC
import { getPartnerId } from '../../helpers'

//CONSTANTS
import * as types from "../constants";

//TYPES
import { AnyAction } from 'redux';
import { Car } from '../../types';

const initialState : Car = {
  damages: [],
  carDamageZone: [],
  photos: {
    overview: [{
      name: 'overview',
      images:[],
      long_name: 'overview'
    }],
    close_ups: [],
    isUpload: false
  },
  coords: [],
  form: {
    reg_nr: '',
    errors: ['reg_nr'],
    carRegistration: false
  },
  sessionUniqueId: random(10),
  partner : {},
  ownCar: {
    carName: '',
    brand : '',
    series : '',
    model: ''
  },
  isOtherPartSelected: false,
  isFormSubmitting: false 
};

export default function carReducer(state = initialState, action: AnyAction) { 
  switch (action.type) {
    case types.SET_CAR_DAMAGES:
      return { ...state, damages: action.payload };
    case types.SET_CAR_NAME:
      return { ...state, ownCar: action.payload };
    case types.FORM_SUBMIT:
      return { ...state, isFormSubmitting: action.payload };  
    case types.SELECT_OTHER_PART_DAMAGES:
        return { ...state, isOtherPartSelected: action.payload };    
    case types.SET_ADDITION_DAMAGE_PART:
        return { ...state, photos: {...state.photos, close_ups: action.payload} };     
    case types.CREATE_CAR_IMAGES_LIST:
        return { ...state, photos: {...state.photos, close_ups: action.payload}};
    case types.SET_PARTNER_DATA:
        return { ...state, partner: action.payload};    
    case types.ADD_CAR_IMAGES:
        return { ...state, photos: {...state.photos,[action.payload.key]: action.payload.images} };
    case types.SET_CAR_COORDS:      
        return { ...state, coords: action.payload };
    case types.ON_CHANGE:
      return { ...state, form: {...state.form,[action.payload.name]: action.payload.value} };
    case types.SET_FORM_ERROR:
      return { ...state, form: {...state.form, errors: action.payload } };
    case types.IS_CAR_REGISTERED:
      return { ...state, form: {...state.form, carRegistration: action.payload } }; 
    case types.CLEAR_ALL_DATA:
      return {  ...initialState,coords: action.payload, partner:state.partner, sessionUniqueId: random(10) };
    case types.SET_DAMAGES_ZONE : 
      return { ...state, carDamageZone: action.payload };
    case types.UPLOAD_IMAGE : 
      return { ...state, photos: {...state.photos, isUpload: action.payload} };         
    default:
      return state;
  }
}