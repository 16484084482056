// MAIN MODULES
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import  {store , persistor} from './store';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { ApolloProvider } from '@apollo/client';

import { client } from './store'

// PAGES
import Main from './pages';

// STYLES
import './App.scss';

// INIT BASE THEME FOR APP
const theme = createTheme({
    palette: {
      primary: {
          main: '#274D82'          
      } ,
      secondary: {
        main: '#00CC83'
      }          
    }
  })

  
class App extends React.Component {    

render() {
        return (
            <ApolloProvider client={client}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <MuiThemeProvider theme={theme}>           
                        <Main/>
                    </MuiThemeProvider>      
                </Router>
                </PersistGate>
            </Provider>
            </ApolloProvider>
            
        );
    }
}
export default App;