// COMMON PART in CAR
export const COMMON_DAMAGES_PART = ['bumper','bonnet','window','roof','back_bumper','rear_door','back_window'];

export const TRANSLATION_CAR_PART:{[key:string]:string} = {
    bumper: 'främre stötfångare',
    bonnet: 'motorhuv',
    window: 'vindruta',
    roof : 'tak',
    back_bumper: 'bakre stötfångare',
    rear_door: 'baklucka',
    back_window: 'bakruta',
    light : 'främre strålkastare',
    fender: 'framskärm',
    wheel1: 'framhjul',
    wheel2: "bakhjul",
    hem: 'nederkant',
    door1: 'framdörr',
    door2: 'bakdörr',
    front_window: 'främre  fönster',
    rear_window: 'bakre fönsterr',
    rear_mudguard: 'bakskärm',
    mirror: 'backspegel',
    roof_edge : 'Kanten på taket',
    rear_light: 'bakljus',
    other_part: 'Annan bildel'
}

// CAR
export const SET_CAR_DAMAGES = 'SET_CAR_DAMAGES';
export const CREATE_CAR_IMAGES_LIST = 'CREATE_CAR_IMAGES_LIST';
export const ADD_CAR_IMAGES  = "ADD_CAR_IMAGES";
export const SET_CAR_COORDS = 'SET_CAR_COORDS';
export const ON_CHANGE = 'ON_CHANGE';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const IS_CAR_REGISTERED = 'IS_CAR_REGISTERED';
export const SET_DAMAGES_ZONE = 'SET_DAMAGES_ZONE';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const SET_PARTNER_DATA = 'SET_PARTNER_DATA';
export const SET_CAR_NAME = 'SET_CAR_NAME';
export const SET_ADDITION_DAMAGE_PART =  'SET_ADDITION_DAMAGE_PART';
export const SELECT_OTHER_PART_DAMAGES = 'SELECT_OTHER_PART_DAMAGES';
export const FORM_SUBMIT = 'FORM_SUBMIT';

//SETTING
export const SET_GLOBAL_SETTING = 'SET_GLOBAL_SETTING';