// MAIN MODULES
import { combineReducers } from 'redux';

//REDUCERS

import car from './car'
import settings from './settings'


const rootReducer = combineReducers({
    car,
    settings
});

export default rootReducer;