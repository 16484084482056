// MAIN MODULES
import  { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import { ApolloClient, InMemoryCache ,HttpLink } from '@apollo/client';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import thunk from "redux-thunk";

// MAIN REDUCERS
import mainReduser from './reducers';
import { isNonEmptyArray } from '@apollo/client/utilities';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, mainReduser)

// const HASURA_URL : any = process.env.NODE_ENV === 'production' ? 'https://api.aftenbil.se/v1/graphql' : 'https://aftenbil-hasura-dev.kumobits.com/v1/graphql';
const HASURA_URL : any = 'https://api.aftenbil.se/v1/graphql';

// APOLLO 
export const client = new ApolloClient({
  link: new HttpLink({          
          
          uri: HASURA_URL,
          headers: {
            'x-hasura-role': 'user',
            //  'authorization' : 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoidGVzdDEyMyIsImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJ1c2VyIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6InVzZXIiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMzZjMTUzZTEtOThkOS00N2ViLWJmNjctMWNhZDk5ZmRiY2M3In0sImlhdCI6MTYxNTQ1MDk5MCwiZXhwIjo0NzM5NjUzMzkwLCJzdWIiOiIzNmMxNTNlMS05OGQ5LTQ3ZWItYmY2Ny0xY2FkOTlmZGJjYzcifQ.U8R5lvXzcvjF8ZLgSL2Po4QYE5MX5sC7mkdFIX5NmlTOzZwxQaOVPz3m8mrrwJz6dusTK6OIMJKKvOGzCiRbH9GyzqDSIHh-Gnc293F8Ou7YTTmqf1j6yVvKQklqaU0-oHvva_D4li4n46z6pLZXYcTuZyouqxm1t13HJHNeda2GLsOnRogs3nHkBwm36ujr8iMiP1cr8o2tx4RLpaiXreyYhX4D62H58Agg31ik-8sPo5I6oD-wSop-BCPdKUnXeKZLH50xO9kP7qWUSYni7bsCYxxil5cYBKgIDh3cdr4P29ScOyxxA2AKkSr0NjE0fQ5T6ALAtLlinY89NRpfZQ'
             'authorization' : 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoidGVzdDEyMyIsImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJ1c2VyIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6InVzZXIiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMzZjMTUzZTEtOThkOS00N2ViLWJmNjctMWNhZDk5ZmRiY2M3In0sImlhdCI6MTYxNTQ1MzIxNSwiZXhwIjo0NzM5NjU1NjE1LCJzdWIiOiIzNmMxNTNlMS05OGQ5LTQ3ZWItYmY2Ny0xY2FkOTlmZGJjYzcifQ.pmNZtG55XZBuPCBCPv84AEwYYGs4OkfVSUCpbl3-l7I0kyd_JmaaRwM0Et_jqcBm0-H8ClDdJjbOGGpHxY0qNdP8y8MeXzB1Uf7g5JJKaoJd-4IwwssXNXVkVhv-yXEkEF9pG0ABmETGUd_mr13Z9QWIAm0gFTdX4IrHYIsdIsV9NoITFbbIVIxYJoqic4LAvNGj2vC7TiczJoI4WUW9BVdsIGn3MnqiyerUpaZW9DOCpDMCkZ66woOT9uU3cM_GfMYEYjsjy1oSzS-9Z15XE0Y59eNaSJjXzFfFJfISNplllDD3TXMvd829oJcBE2gfa4x4Z5qtkgSORItZFIjQGQ'
          },                   
    }),
cache: new InMemoryCache(),
connectToDevTools: true,

});

export const store   = createStore(
  persistedReducer,
    composeWithDevTools(applyMiddleware(thunk.withExtraArgument(client)))
  );

export let persistor = persistStore(store)   
